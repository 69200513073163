<template>
    <div class="card align-middle" style="padding:15px;width:300px;margin:15% auto;max-height:700px;">
        <h3>로그인</h3>
        <form @submit="onSubmit">
        <div style="width:100%;height:120px;">
            <!--<label for="inputEmail" class="sr-only">Your ID</label>-->
            <input type="text" id="uid" class="form-control" placeholder="Your ID" required autofocus v-model="uid"><br/>
            <!--<label for="inputPassword" class="sr-only">Password</label> -->
            <input type="password" id="password" class="form-control" placeholder="Password" required v-model="password"><br>
            
            <button id="btn-Yes" class="btn btn-lg btn-primary btn-block" type="submit" style="margin-top:30px;">로 그 인</button>
        </div>
        </form>
    </div>
</template>

<script>
import Constant from '../Constant';

export default{
 name: 'Login',
 data: () => ({
     uid: '',
     password: ''
 }),
 beforeCreate: function(){
    if(this.$store.state.uid != "UID" && this.$store.state.uid != "" && this.$store.state.uid != "" ){
        this.$router.push("intraHome");
    }
 },
 methods: {
     //...mapActions(['login']),
    onSubmit() {
         console.log(this.uid);
         console.log(this.password);

        this.$store.dispatch(Constant.LOGIN, { uid: this.uid, password: this.password})
        .then(() => {
            this.$router.push("intraHome")
        })
        /*
         try{
             let loginResult = await this.login({uid: this.uid, password: this.password});
             console.log(loginResult);
         }
         catch(err){
             console.error(err);
         }
         */
     }
 }
}
</script>