<template>
    <div>
        <!--Top Menu -->
        <intra-top></intra-top>

        <!-- Contents -->
        <div class="row justify-content-center" style="min-width:600px;">
            <div class="row">
                <h2 class="card-title" style="font-weight:bold;margin:8px 0 -8px 0;"><i class="fas fa-square"></i> 사업실적 상세보기</h2>
                <div class="card-body">
                    <div class="table-responsive table-bordered">
                        <table class="table">
                            <colgroup>
                                <col style="width:120px" />
                                <col style="" />
                                <col style="width:110px" />
                                <col style="" />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th class="table-active w120">사업 번호</th>
                                    <td class="txtL">{{performance.biz_id}}</td>
                                    <td rowspan="4" colspan="2" style="">
                                        <img :src="'/img/biz/'+performance.img" style="width:100%;max-width:150px;max-height:250px"/>
                                    </td>
                                </tr>
                                <tr>
                                    <th class="table-active w120">사업 코드</th>
                                    <td class="txtL">{{performance.biz_code}}</td>
                                </tr> 
                                <tr>
                                    <th class="table-active w120">발주처</th>        
                                    <td class="txtL">{{performance.biz_order}}</td>
                                </tr>
                                <tr>
                                    <th class="table-active w120">고객사</th>    
                                    <td class="txtL">{{performance.biz_customer}}</td>
                                </tr>
                                <tr>
                                    <th class="table-active w120">사업 이름</th>
                                    <td class="txtL" colspan="3">{{performance.biz_name}}</td>
                                </tr>
                                <tr>
                                    <th class="table-active w120">사업 시작</th>
                                    <td class="txtL">{{performance.from_dt}}</td>
                                    <th class="table-active w110">사업 종료</th>
                                    <td class="txtL">{{performance.to_dt}}</td>
                                </tr>
                                <tr>
                                    <th class="table-active w120">사업 기반기술</th>
                                    <td class="txtL" colspan="3">{{performance.biz_skill}}</td>
                                </tr>
                                <tr>
                                    <th class="table-active w120">메모</th>
                                    <td class="txtL" colspan="3">{{performance.memo}}</td>
                                </tr>
                                <tr>
                                    <th class="table-active w120">등록일자</th>
                                    <td class="txtL">{{performance.reg_dt}}</td>
                                    <th class="table-active w110">수정일자</th>
                                    <td class="txtL">{{performance.upt_dt}}</td>
                                </tr>
                                <tr>
                                    <th class="table-active w120">공개여부</th>
                                    <td class="txtL">{{performance.open_yn_name}}</td>
                                    <th class="table-active w110">사용여부</th>
                                    <td class="txtL">{{performance.use_yn_name}}</td>
                                </tr>
                                
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div class="justify-content-between">
                <button type="button" class="btn btn-success" @click="goList">목록</button>
                <button type="button" class="btn btn-success" @click="bizModify" style="margin-left:8px">수정</button>
                
            </div>
        </div>  
    </div>
</template>



<script type="text/javascript">
import Constant from '../../Constant';
import { mapState } from 'vuex';
import IntraTop from './IntraTop';
//import axios from 'axios';

export default { 
    name : 'biz-detail',
    components : { IntraTop},
    beforeCreate: function(){
        console.log('BizDetail beforeCreate...');
        this.$store.dispatch(Constant.LOGIN_CHECK, this.$store.state.uid);
        //this.$store.dispatch(Constant.BOARD_DETAIL, this.nttId);
        /*
        if(this.$store.state.uid == "UID"){
            console.log("before:UID:" + this.$store.state.uid);
            this.$router.push('login');
        }
        else{
            console.log("before:UID:" + this.$store.state.uid);
        }
        */
    },
    
    //props : ['boardId', 'nttId'],
    computed: {
       ...mapState([ 'performance' ]),
    },
    methods: {
       
        goList: function(){
            this.$router.go(-1);
        },
        bizModify: function(){
            this.$store.dispatch(Constant.BIZ_MODIFY);
        },
        
    },
    mounted : function(){
        //console.log("mounted::" + this.boardId);
        //console.log("mounted::" + this.boardMode);
    }
    
}

</script>