<template>
    <div>
        <!--Top Menu -->
        <intra-top></intra-top>

        <!-- Contents -->
        <form name="f" method="post" ref="f" @submit.prevent="bizSubmit" enctype="multipart/form-data">
        
        <div class="row justify-content-center" style="min-width:600px;">
            <div class="row">
                <h2 class="card-title" style="font-weight:bold;margin:8px 0 -8px 0;"><i class="fas fa-square"></i> 사업실적 신규등록</h2>
                <div class="card-body">
                    <div class="table-responsive table-bordered">
                        <table class="table">
                            <colgroup>
                                <col style="width:120px" />
                                <col style="" />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th class="table-active w120">사업이름</th>
                                    <td><input type="text" v-model="performance.biz_name" style="width:100%" /></td>
                                </tr>
                                <tr>
                                    <th class="table-active w120">발주처</th>
                                    <td><input type="text" v-model="performance.biz_order" style="width:100%" /></td>
                                </tr>
                                <tr>
                                    <th class="table-active w120">고객사</th>
                                    <td><input type="text" v-model="performance.biz_customer" style="width:100%" /></td>
                                </tr>
                                <tr>
                                    <th class="table-active w120">사업 시작일자</th>
                                    <td><input type="text" v-model="performance.from_dt" style="width:100%" placeholder="yyyy-mm"/></td>
                                </tr>
                                <tr>
                                    <th class="table-active w120">사업 종료일자</th>
                                    <td><input type="text" v-model="performance.to_dt" style="width:100%" placeholder="yyyy-mm"/></td>
                                </tr>
                                <tr>
                                    <th class="table-active w120">사업 기반기술</th>        
                                    <td ><input type="text" v-model="performance.biz_skill" style="width:100%" /></td>
                                </tr>
                                <tr>
                                    <th class="table-active w120">메모</th>        
                                    <td><input type="text" v-model="performance.memo" style="width:100%" /></td>
                                </tr>
                                <tr>
                                    <th class="table-active w120">공개여부</th>        
                                    <td class="txtL">
                                        <select v-model="performance.open_yn" class="bdSrchOp2">
                                            <option value="">--- 선택 ---</option>
                                            <option v-for="option in openOptions" :key="option.value" :value="option.value">
                                                {{option.text}}
                                            </option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <th class="table-active w120">이미지</th>
                                    <td><input type="file" multiple name="file" id="file" style="width:100%" @change="onFileChange"/></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
              
            </div>

            <div class="justify-content-between">
                <button type="button" class="btn btn-success" @click="goList">목록</button>
                <button type="submit" class="btn btn-success" style="margin-left:8px">등록</button>
                
            </div>
        </div>
        </form>
    </div>
</template>


<script type="text/javascript">
import Constant from '../../Constant';
import axios from 'axios';
import { mapState } from 'vuex';
import IntraTop from './IntraTop';

export default {
    name : 'biz-form', 
    component: {axios, IntraTop},
    beforeCreate: function(){
        console.log('BizForm beforeCreate...');
         this.$store.dispatch(Constant.LOGIN_CHECK, this.$store.state.uid);
         
        /*
        if(this.$store.state.uid == "UID"){
            console.log("before:UID:" + this.$store.state.uid);
            this.$router.push('login');
        }
        else{
            console.log("before:UID:" + this.$store.state.uid);
        }
        */
    },
    data : function() {
        return {
            filesTempArr : [],
            openOptions: [
                {text: '공개', value:'t'},
                {text: '비공개', value:'f'},
            ]
        }
    },
    props : ['bizMode', 'bizId'],
    computed: {
        ...mapState([ 'performanceList', 'uid']),
        performance() {
          
            return this.$store.state.performance_empty;
        }
    },
    methods: {
        bizSubmit: function(e){
            
            if( !confirm("등록하시겠습니까?")){
                return false;
            }
            console.log(e.target);

            console.log(this.performance.biz_id);
            console.log(this.performance.biz_name);
            console.log(this.performance.biz_code);
                        
            
            /* 2022.07.24 신규코드 - 파일첨부 테스트 */
            
            const formdata = new FormData();    //formData 선언
            //const files = e.target.files;
            formdata.append("mode", this.bizMode);
            formdata.append("uid", this.$store.state.uid);
            formdata.append("biz_name", this.performance.biz_name);
            formdata.append("biz_code", this.performance.biz_code);
            formdata.append("biz_order", this.performance.biz_order);
            formdata.append("biz_customer", this.performance.biz_customer);
            formdata.append("from_dt", this.performance.from_dt);
            formdata.append("to_dt", this.performance.to_dt);
            formdata.append("biz_skill", this.performance.biz_skill);
            formdata.append("open_yn", this.performance.open_yn);
            formdata.append("memo", this.performance.memo);
            formdata.append('files', this.filesTempArr[0]);

            console.log(...formdata.entries());
            this.$store.dispatch(Constant.BIZ_ADD, formdata);

            /* 2022.07.24 정상코드 
            this.$store.dispatch(Constant.BOARD_ADD, {bulletin: this.bulletin, mode: this.boardMod, board_id: this.boardId, uid: this.uid});
            */

        },
        onFileChange: function(e){
            
           const files = e.target.files;
           console.log("file::" + files[0]);
           
           this.filesTempArr[0] = files[0];
           console.log("files:" + this.filesTempArr[0]);
        },
        goList: function(){
            this.$router.push("/intranet/bizList");
        }
    },
    mounted : function(){
        console.log("mounted::" + this.bizMode);
    }
    
}

</script>