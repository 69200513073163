<template>
    <div>
       <!--Top Menu -->
       <intra-top></intra-top>

        <!-- 2022.07.24 정상코드-->
        <form name="f" method="post" ref="f" @submit.prevent="boardSubmit" enctype="multipart/form-data">
        
        <div class="row justify-content-center" style="min-width:600px;">
                <div class="row">
                    <h4 class="card-title" style="font-weight:bold;margin:8px 0 -8px 0;"><i class="fas fa-square"></i> 공지시항 등록하기 </h4>
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table">
                                <tbody>
                                    <tr>
                                        <th class="table-active w120">작성자</th>
                                        <td class="txtL">{{uid}}</td>
                                    </tr>
                                    <tr>
                                        <th class="table-active">공개여부</th>
                                        <td class="txtL">
                                            <select v-model="bulletin.board_id" class="bdSrchOp" style="width:150px">
                                                <option value="B01">인트라넷(비공개)</option>        
                                                <option value="B01-1">홈페이지(공개)</option>        
                                            </select>
                                        </td>
                                    </tr>
                                    <tr style="line-height:32px;" class="">
                                        <th class="table-active">제목</th>
                                        <td><input type="text"  v-model="bulletin.subject" style="width:100%" /></td>
                                    </tr>
                                    <tr style="line-height:32px;" class="">
                                        <td class="table-active">내용</td>
                                        <td><textarea v-model="bulletin.contents" style="width:100%;height:400px;"></textarea></td>
                                    </tr>
                                    <tr style="line-height:32px;" class="">
                                        <td class="table-active">첨부파일</td>
                                        <td><input type="file" multiple name="file" id="file" style="width:100%" @change="onFileChange"/></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                
                <div class="justify-content-between" style="margin-bottom:30px">
                    <button type="button" class="btn btn-success" @click="goList">목록</button>
                    <button type="submit" class="btn btn-success" style="margin-left:8px">저장</button>
                    <!--<button type="button" class="btn btn-success" @click="boardSubmit" style="margin-left:8px">저장</button>-->
                    <button type="button" class="btn btn-info" style="display:none">다시쓰기</button>
                </div>
        </div>
        </form>
    </div>
</template>

<!--<script setup>
    const submitBoard = e =>{
        const form = e.target;
        console.log(...new FormData(form).entries());
    }
</script>
-->

<script type="text/javascript">
import Constant from '../../Constant';
import axios from 'axios';
import { mapState } from 'vuex';

export default {
    name : 'board-form',
    component: {axios},
    beforeCreate: function(){
        console.log('BoardForm beforeCreate...');
         this.$store.dispatch(Constant.LOGIN_CHECK, this.$store.state.uid);
         
        /*
        if(this.$store.state.uid == "UID"){
            console.log("before:UID:" + this.$store.state.uid);
            this.$router.push('login');
        }
        else{
            console.log("before:UID:" + this.$store.state.uid);
        }
        */
    },
    data : function() {
        return {
            filesTempArr : [],
            
        }
    },
    props : ['boardId', 'boardMode', 'nttId'],
    computed: {
        ...mapState([ 'noticeList', 'uid']),
        bulletin() {
            
            var bulletin_empty = this.$store.state.bulletin_empty;
            bulletin_empty.board_id = "B01";
            return bulletin_empty;
        }
        /*
        bulletin() {
            return{
                type: Object,
                mode: this.boardMode,
                board_id: Constant.BOARD_NOTICE,
                subject: '',
                contents: '',
                upt_id: this.uid,
                uid: this.uid

            }
        } 
        */           
    },
    methods: {
        boardSubmit: function(e){
            
            if( !confirm("등록하시겠습니까?")){
                return false;
            }
            console.log(e.target);

            console.log(this.bulletin.subject);
            console.log(this.bulletin.upt_id);
            console.log(this.bulletin.uid);
            console.log("uid::" + this.bulletin.uid);

            /* 2022.07.24 신규코드 - 파일첨부 테스트 */
            
            const formdata = new FormData();    //formData 선언
            //const files = e.target.files;
            formdata.append("mode", this.boardMode);
            formdata.append("boardId", this.bulletin.board_id);
            formdata.append("uid", this.$store.state.uid);
            formdata.append("subject", this.bulletin.subject);
            formdata.append("contents", this.bulletin.contents);
            formdata.append('files', this.filesTempArr[0]);

            console.log(...formdata.entries());
            this.$store.dispatch(Constant.BOARD_ADD, formdata);

            /* 2022.07.24 정상코드 
            this.$store.dispatch(Constant.BOARD_ADD, {bulletin: this.bulletin, mode: this.boardMod, board_id: this.boardId, uid: this.uid});
            */

        },
        onFileChange: function(e){
            
            //const formData = new FormData();	// 파일을 전송할때는 FormData 형식으로 전송
            //this.uploadimageurl = [];		// uploadimageurl은 미리보기용으로 사용
            const files = e.target.files;
            console.log("file::" + files[0]);
            //formData.append('files', files[0])	// formData의 key: 'filelist', value: 이미지


           /* files.forEach((item) => {
                formData.append('files', item)	// formData의 key: 'filelist', value: 이미지
                const reader = new FileReader();
                //reader.onload = (e) => {
                    //this.uploadimageurl.push({url: e.target.result});
                    // e.target.result를 통해 이미지 url을 가져와서 uploadimageurl에 저장
                //};
                reader.readAsDataURL(item);
            });*/

            /*
            axios({
                url: "/backend/file/fileAdd",	// 이미지 저장을 위해 back서버와 통신
                method: "POST",
                headers: {'Content-Type': 'multipart/form-data'},	// 이걸 써줘야 formdata 형식 전송가능
                data: formData,
            }).then(res => {
                console.log(res);
               // this.imagecnt = file.length;	// 이미지 개수 저장
            }).catch(err => {
                alert(err);
            });
            */

           this.filesTempArr[0] = files[0];
           console.log("files:" + this.filesTempArr[0]);
        },
        goList: function(){
            this.$router.push("/intraHome");
        }
    },
    mounted : function(){
        console.log("mounted::" + this.boardId);
        console.log("mounted::" + this.boardMode);
    }
    
}

</script>