<template>
    <div class="header">
            <div style="max-height:80px;min-height:80px;">
                <div style="height:80px;margin-top:10px;padding:5px 10px;">
                    <nav class="navbar navbar-expand-lg navbar-light bg-light">
                        <div class="container-fluid">
                            <a class="navbar-brand" href="/">IWE홈</a>
                            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span class="navbar-toggler-icon"></span>
                            </button>
                            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                                    <li class="nav-item">
                                        <router-link to="/intranet/notice"  class="nav-link active">공지사항</router-link>
                                    </li>
                                    <li class="nav-item">
                                        <router-link to="/intranet/free"  class="nav-link active">자유게시판</router-link>
                                    </li>
                                    <li class="nav-item">
                                        <router-link to="/intranet/userList"  class="nav-link active">사원정보관리</router-link>
                                    </li>
                                    <li class="nav-item">
                                        <router-link to="/intranet/bizList"  class="nav-link active">사업실적관리</router-link>
                                    </li>

                                </ul>
                                <form class="d-flex">
                                    <button class="btn btn-outline-success me-2" @click="logout">로그아웃</button>
                                </form>
                            </div>
                        </div>
                        
                    </nav>
                </div>
            </div>
        </div>
</template>

<style lang="scss">
@import url("../../../public/css/iwe.css");

</style>

<script type="text/javascript">

import Constant from '../../Constant';

export default {
    name : 'intra-top',
  
    computed : {
   
    },
    
    mounted : function(){
        
    },
    methods : {
        logout : function(){
            console.log('logout');
            
            this.$store.dispatch(Constant.LOGOUT, this.$store.state.uid);
        },
    }
}
</script>