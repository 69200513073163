<template>
    <div>
   
         <!--Top Menu -->
        <intra-top></intra-top>

        <!-- Contents -->
        <div class="row gtr-0 justify-content-between" style="margin:0px; padding:0;">
            <div class="row " style="margin:0px;padding:0;">
                    <div class="col" style="overflow:auto;max-width:1920px;padding:0">
                        <div class="row" style="margin:0px;padding:0;">
                            <h2 style="padding:0px;">공지사항</h2>
                            <!--<div style="width:100%;border:1px solid #d4d4d4;color:black;">
                                <ul>
                                    <li v-for="notice in noticeList.noticeList" :key="notice.ntt_id">{{notice.subject}}</li>
                                </ul>
                            </div>-->
                            <div class="col">

                                <!--
                                <b-table hover show-empty="true"
                                    id="iwe-notice"
                                    :items="noticeLists"
                                    :per-page="5"
                                    :current-page="currentPage"
                                    :fields="fields"
                                empty-text="검색 결과가 없습니다."
                                >
                                <template #cell(boardDetail)="data">
                                {{data.subject}}
                                </template>

                                
                                    
                                </b-table>
                                -->
                                <table class="table table-striped table-hover" id="iwe-notice">
                                    <colgroup>
                                        <col style="" />
                                        <col style="width:100px;" />
                                        <col style="width:60px;" />
                                    </colgroup>
                                    <thead class="thead-light">
                                        <tr>
                                            <th scope="col">제목</th>
                                            <th scope="col">수정일자</th>
                                            <th scope="col">HIT</th>
                                            <!--<th scope="col">상세보기</th>-->
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr scope="row" v-for="notices in noticeLists" :key="notices.ntt_id" @click="boardDetail(notices.ntt_id, $event)">
                                            <td class="txtL">{{notices.subject}}</td>
                                            <td>{{notices.upt_dt}}</td>
                                            <td>{{notices.read_cnt}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <b-pagination
                                    v-model="currentPage"
                                    :total-rows="rows"
                                    :per-page="perPage"
                                    aria-controls="iwe-notice">
                                </b-pagination>
                            </div>
                            
                        </div><!--row-->
                    </div>
            </div>

            <!--검색-->
            <div class="row" style="padding:0; margin:0;">
                <boardSearch></boardSearch>
            </div>
            
            <div class="row justify-content-end" style="margin:5px 0px !important; padding:0 !importnat;height:30px;">
                <button class="basicBtn btn btn-primary" @click="boardForm">글쓰기</button>
            </div>

            <!-- <v-pagination v-model="page" :pages="10" :range-size="1" active-color="#DCEDFF" @update:modelValue="pageChanged"/> -->
        </div>
    </div>
</template>

<style lang="scss">
@import url("../../../public/css/iwe.css");

</style>

<script type="text/javascript">
import Constant from '../../Constant';
import { mapState } from 'vuex';
import BoardSearch from './BoardSearch';
import IntraTop from './IntraTop';

export default {
    name : 'intra-notice-list',
    components : { BoardSearch, IntraTop },
    beforeCreate: function(){
        console.log('IntraNoticeList beforeCreate...');
         this.$store.dispatch(Constant.LOGIN_CHECK, this.$store.state.uid);
        /*
        if(this.$store.state.uid == "UID"){
            console.log("before:UID:" + this.$store.state.uid);
            this.$router.push('login');
        }
        else{
            console.log("before:UID:" + this.$store.state.uid);
        }
        */
    },
    data(){
        return {
           
            currentPage: 1,
            perPage: 10,
            fields:[
                {
                    key: 'ntt_id', label: '글번호', tdClass: 'w100'
                },
                {
                    key: 'subject', label: '제목', tdClass: 'txtL minw150'
                },
                {
                    key: 'upt_id', label: '글쓴이', tdClass: 'w150'
                },
                {
                    key: 'upt_dt', label: '수정일자', tdClass: 'w120'
                },
                {
                    key: 'read_cnt', label: '조회수', tdClass: 'w100'
                }
            ]
        }
    },
    computed : {
    //    totalPage : function(){
     //       console.log("computed:totalPage:" + this.noticeList.totalCount);
      //      console.log("computed:totalPage:" + this.noticeList.pageSize);
       //     return 1;
       //     //return Math.floor((this.noticeList.totalCount -1 ) / this.noticeList.pageSize) +1;
       // },
       ...mapState([ 'noticeList' ]),
       rows(){
           console.log("rows:" + this.$store.state.noticeList.totalCount);
           return this.$store.state.noticeList.totalCount;
       },
       noticeLists(){
           console.log("noticeLists:" + this.$store.state.noticeList.noticeList.length);

           return this.$store.state.noticeList.noticeList.slice((this.currentPage -1) * this.perPage, this.currentPage * this.perPage);
       }
    },
    
    mounted : function(){
        var page = 1;
        console.log("mounted:" + this.$route.query);
        console.log("mounted:" + this.$route.query.page);

        if(this.$router.query && this.$route.query.page){
            page = parseInt(this.$route.query.page);
            this.currentPage = page;
        }
       
       this.$store.dispatch(Constant.NOTICE_LIST, page);
        //this.$refs.pagebuttons.selected = page-1;

        console.log("mounted:" +  this.currentPage);
    },
    methods : {
        pageChanged : function(page){
            console.log(page);
            this.$router.push({name: 'intraNotice', query: { page:page }});
        },
        boardForm : function(){
            console.log('boardForm()');
            this.$router.push({name: 'boardForm', params: { boardMode: 'ADD', boardId: Constant.BOARD_NOTICE, nttId:'' }});
        },
        boardDetail : function(ntt_id, event){
            console.log(event);
            this.$store.dispatch(Constant.BOARD_DETAIL, { nttId: ntt_id });
            //this.$router.push({name: 'boardForm', params: {boardMode: 'MOD', boardId: Constant.BOARD_NOTICE, nttId: ntt_id}})
        }
    }
}
</script>