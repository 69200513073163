<template>
    <div>
        <!--Top Menu -->
        <intra-top></intra-top>

        <!-- Contents -->
        <form name="f" method="post" ref="f" @submit.prevent="userSubmit" enctype="multipart/form-data">
        
        <div class="row justify-content-center" style="min-width:600px;">
            <div class="row">
                <h2 class="card-title" style="font-weight:bold;margin:8px 0 -8px 0;"><i class="fas fa-square"></i> 사원 신규등록</h2>
                    <div class="card-body">
                        <div class="table-responsive table-bordered">
                            <table class="table">
                                <colgroup>
                                    <col style="width:120px" />
                                    <col style="" />
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <th class="table-active w120">ID</th>
                                        <td><input type="text" v-model="employee.user_id" style="width:100%" placeholder="3~12자리 영문,숫자,!@_-."/></td>
                                    </tr>
                                    <tr>
                                        <th class="table-active w120">이름</th>
                                        <td><input type="text" v-model="employee.user_name" style="width:100%" /></td>
                                    </tr>
                                    <tr>
                                        <th class="table-active w120">비밀번호</th>
                                        <td><input type="password" v-model="employee.user_pw" style="width:100%" /></td>
                                    </tr>
                                    <tr>
                                        <th class="table-active w120">비밀번호 확인</th>
                                        <td><input type="password" v-model="employee.user_pw_chk" style="width:100%" /></td>
                                    </tr>
                                    <tr>
                                        <th class="table-active w120">전화번호</th>
                                        <td><input type="text" v-model="employee.tel_no" style="width:100%" /></td>
                                    </tr>
                                    <tr>
                                        <th class="table-active w120">모바일</th>
                                        <td><input type="text" v-model="employee.mobile_tel_no" style="width:100%" /></td>
                                    </tr>
                                    <tr>
                                        <th class="table-active w120">이메일</th>        
                                        <td colspan="3"><input type="text" v-model="employee.e_mail" style="width:100%" /></td>
                                    </tr>
                                    <tr>
                                        <th class="table-active w120">부서</th>
                                        <td>
                                            <select v-model="employee.dept_id" class="bdSrchOp">
                                                <option value="">--- 선택 ---</option>
                                                <option v-for="option in deptOptions" :key="option.value" :value="option.value">
                                                    {{option.text}}
                                                </option>
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th class="table-active w120">직급</th>
                                        <td>
                                            <select v-model="employee.posi_id" class="bdSrchOp">
                                                <option value="">--- 선택 ---</option>
                                                <option v-for="option in posiOptions" :key="option.value" :value="option.value">
                                                    {{option.text}}
                                                </option>
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th class="table-active w120">사용자 권한</th>
                                        <td>
                                            <select v-model="employee.user_grant" class="bdSrchOp">
                                                <option value="">--- 선택 ---</option>
                                                <option v-for="option in grantOptions" :key="option.value" :value="option.value">
                                                    {{option.text}}
                                                </option>
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th class="table-active w120">우편번호</th>
                                        <td><input type="text" v-model="employee.zip_code" style="width:30%;min-width:150px;float:left;" /></td>
                                    </tr>
                                    
                                    <tr>
                                        <th class="table-active w120">주소</th>
                                        <td><input type="text" v-model="employee.addr1" style="width:100%;" /></td>
                                    </tr>
                                    <tr>
                                        <th class="table-active w120">입사일자</th>
                                        <td><input type="text" v-model="employee.enter_dt" style="width:100%;" /></td>
                                    </tr>
                                    <tr>
                                        <th class="table-active w120">사진</th>
                                        <td><input type="file" multiple name="file" id="file" style="width:100%" @change="onFileChange"/></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div class="justify-content-between">
                    <button type="button" class="btn btn-success" @click="goList">목록</button>
                    <button type="submit" class="btn btn-success" style="margin-left:8px">등록</button>
                   
                </div>
        </div>
        </form>
    </div>
</template>


<script type="text/javascript">
import Constant from '../../Constant';
import axios from 'axios';
import { mapState } from 'vuex';

export default {
    name : 'board-form',
    component: {axios},
    beforeCreate: function(){
        console.log('UserForm beforeCreate...');
         this.$store.dispatch(Constant.LOGIN_CHECK, this.$store.state.uid);
         
        /*
        if(this.$store.state.uid == "UID"){
            console.log("before:UID:" + this.$store.state.uid);
            this.$router.push('login');
        }
        else{
            console.log("before:UID:" + this.$store.state.uid);
        }
        */
    },
    data : function() {
        return {
            filesTempArr : [],
            posiOptions: [
                {text: '사원', value:'100'},
                {text: '주임', value:'200'},
                {text: '대리', value:'300'},
                {text: '과장', value:'400'},
                {text: '차장', value:'500'},
                {text: '부장', value:'600'},
                {text: '임원', value:'700'},
                {text: 'CEO', value:'900'},
                {text: '기타', value:'010'},
            ],
            deptOptions: [
                {text: '개발1팀', value:'100'},
                {text: '개발2팀', value:'200'},
                {text: '지원', value:'300'},
                {text: '총무', value:'400'},
                {text: 'CEO', value:'900'},
            ],
            grantOptions: [
                {text: '일반', value:'100'},
                {text: '관리자', value:'900'},
            ]
        }
    },
    props : ['userMode', 'userId'],
    computed: {
        ...mapState([ 'userList', 'uid']),
        employee() {
          
            return this.$store.state.employee_empty;
        }
    },
    methods: {
        userSubmit: function(e){
            
            if( !confirm("등록하시겠습니까?")){
                return false;
            }
            console.log(e.target);

            console.log(this.employee.user_id);
            console.log(this.employee.user_name);
            console.log(this.employee.dept_id);
            console.log(this.employee.posi_id);
            
            if(this.employee.user_pw != this.employee.user_pw_chk){
                alert("비밀번호가 일치하지 않습니다.");
                return;
            }

            if(this.employee.dept_id == ""){ alert("부서를 선택하세요."); return; }
            if(this.employee.posi_id == ""){ alert("직급을 선택하세요."); return; }
            if(this.employee.user_grant == ""){ alert("권한을 선택하세요."); return; }

            /* 2022.07.24 신규코드 - 파일첨부 테스트 */
            
            const formdata = new FormData();    //formData 선언
            //const files = e.target.files;
            formdata.append("mode", this.userMode);
            formdata.append("uid", this.$store.state.uid);
            formdata.append("user_id", this.employee.user_id);
            formdata.append("user_pw", this.employee.user_pw);
            formdata.append("user_pw_chk", this.employee.user_pw_chk);
            formdata.append("user_name", this.employee.user_name);
            formdata.append("e_mail", this.employee.e_mail);
            formdata.append("tel_no", this.employee.tel_no);
            formdata.append("mobile_tel_no", this.employee.mobile_tel_no);
            formdata.append("dept_id", this.employee.dept_id);
            formdata.append("posi_id", this.employee.posi_id);
            formdata.append("zip_code", this.employee.zip_code);
            formdata.append("addr1", this.employee.addr1);
            formdata.append("user_grant", this.employee.user_grant);
            formdata.append("day_off", this.employee.day_off);
            formdata.append("enter_dt", this.employee.enter_dt);
            formdata.append('files', this.filesTempArr[0]);

            console.log(...formdata.entries());
            this.$store.dispatch(Constant.USER_ADD, formdata);

            /* 2022.07.24 정상코드 
            this.$store.dispatch(Constant.BOARD_ADD, {bulletin: this.bulletin, mode: this.boardMod, board_id: this.boardId, uid: this.uid});
            */

        },
        onFileChange: function(e){
            
           const files = e.target.files;
           console.log("file::" + files[0]);
           
           this.filesTempArr[0] = files[0];
           console.log("files:" + this.filesTempArr[0]);
        },
        goList: function(){
            this.$router.push("/intranet/userList");
        }
    },
    mounted : function(){
        console.log("mounted::" + this.boardId);
        console.log("mounted::" + this.boardMode);
    }
    
}

</script>