<template>
    <div>
        <!--Top Menu -->
        <intra-top></intra-top>

        <!-- Contents -->
        <div class="row justify-content-center" style="min-width:550px;">
                <div class="row">
                    <h2 class="card-title" style="font-weight:bold;margin:8px 0 -8px 0;"><i class="fas fa-square"></i> 게시글 상세보기</h2>
                    <div class="card-body">
                            <div class="table-responsive table-bordered">
                                <table class="table">
                                    <tbody>
                                        <tr>
                                            <th class="table-active w110">게시글 번호</th>
                                            <td class="txtL">{{bulletin.ntt_id}}</td>
                                        </tr>
                                        <tr>
                                            <th class="table-active">작성자</th>
                                            <td class="txtL">{{bulletin.upt_id}}</td>
                                        </tr>
                                        <tr>
                                            <th class="table-active">조회수</th>
                                            <td class="txtL">{{bulletin.read_cnt}}</td>
                                        </tr>
                                        <tr style="line-height:32px;" class="">
                                            <th class="table-active">제목</th>
                                            <td class="txtL" colspan="3">{{bulletin.subject}}</td>
                                        </tr>
                                        <tr style="line-height:32px;height:300px;" class="">
                                            <th class="table-active">내용</th>
                                            <td class="txtL"  colspan="3">
                                                
                                                <div v-html="bulletin.contents"></div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th class="table-active">첨부파일</th>
                                            <td colspan="3">
                                                <li class="txtL" scope="row" v-for="files in bulletin.files" :key="files.file_id" >
                                                    <a @click="fileDownload(files.file_id, files.org_file_name)" style="cursor:pointer;">{{files.org_file_name}}</a> 
                                                </li>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th class="table-active">수정일자</th>
                                            <td class="txtL">{{bulletin.upt_dt}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                    </div>
                </div>
                
                <div class="justify-content-between" style="margin-bottom:30px;">
                    <button type="button" class="btn btn-success" @click="goList">목록</button>
                    <button type="button" class="btn btn-success" @click="boardModify" style="margin-left:8px">수정</button>
                    <!--<button type="button" class="btn btn-info">다시쓰기</button>-->
                </div>
                
            </div>
    </div>
</template>



<script type="text/javascript">
import Constant from '../../Constant';
import { mapState } from 'vuex';
import IntraTop from './IntraTop';
import axios from 'axios';

export default { 
    name : 'free-detail',
    components : { IntraTop},
    beforeCreate: function(){
        console.log('FreeDetail beforeCreate...');
        this.$store.dispatch(Constant.LOGIN_CHECK, this.$store.state.uid);
        //this.$store.dispatch(Constant.BOARD_DETAIL, this.nttId);
        /*
        if(this.$store.state.uid == "UID"){
            console.log("before:UID:" + this.$store.state.uid);
            this.$router.push('login');
        }
        else{
            console.log("before:UID:" + this.$store.state.uid);
        }
        */
    },
    
    //props : ['boardId', 'nttId'],
    computed: {
       ...mapState([ 'bulletin' ]),
    },
    methods: {
       
        goList: function(){
            this.$router.go(-1);
        },
        boardModify: function(){
            this.$store.dispatch(Constant.FREE_MODIFY);
        },
        async fileDownload(file_id, file_name){
          
            const url = "/backend/file/fileDownload/"+file_id;
            const response = await axios.get(url, { responseType: "blob" });
            const blob = new Blob([response.data]);
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = file_name;
            link.click();
            URL.revokeObjectURL(link.href);

            /*
            axios({
                url : "http://iwe.kr/img/logo_iwetec.gif",
                method : 'GET',
                responseType : 'blob',

            }).then((response) => {

                var fileURL     = window.URL.createObjectURL(new Blob([response.data]));
                var fileLink    = document.createElement('a');

                fileLink.href = fileURL;
                fileLink.setAttribute('download', '1.jpg');
                document.body.appendChild(fileLink);

                fileLink.click();
            }); 
            */

        /*
            const response = await axios.get(url, { responseType: "blob" });
            const blob = new Blob([response.data], { type: "application/pdf" });
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = label;
            link.click();
            URL.revokeObjectURL(link.href);
        */
        }
    },
    mounted : function(){
        //console.log("mounted::" + this.boardId);
        //console.log("mounted::" + this.boardMode);
    }
    
}

</script>