<template>
    <div>
       <div id="mainContents">
            <div class="row">
                <div class="col-sm-12" style="border:1px solid white;">
                   <notice-list></notice-list>
                </div>
               
            </div>
        </div>
    </div>
</template>

<script>

import NoticeList from '../NoticeList.vue';

export default {
    name: 'InfoMain',
    components : { NoticeList },
    data() {
        return{
            height: 0
        }
    },
    
    mounted() {
        // console.log("ready...");
        window.addEventListener('resize', this.handleResize);
	},
    methods: {
        handleResize() {
            this.height = window.innerHeight;
            console.log(this.height);
        }
    }
}
</script>
