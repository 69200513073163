<template>
    <div>
    
    </div>
</template>

<script>

//import Constant from '../Constant';

export default{
 name: 'Intranet',
 created:  function(){
        this.$router.push("/intraHome");
    }
}
</script>
