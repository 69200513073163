<template>
    <div class="row justify-content-between" style="margin:0px !important; padding:0;">
        <div class="col" style="overflow:auto;max-width:1920px;padding:0;margin:0;">
            <div class="row" style="margin:0px;padding:0;">
                <div class="col-md-1" style="margin:0px;padding:0;"></div>
                <div class="col-md-1" style="width:90px; max-width:90px;padding:0 !important;">
                    <select v-model="searchType" class="bdSrchOp">
                        <option v-for="option in searchOptions" :key="option.value" :value="option.value">
                            {{option.text}}
                        </option>
                    </select>
                </div>
                <div class="col-md-2 justify-content-start" style="margin:0;width:300px;padding:0 !important;">
                    <input v-model="searchValue" class="bdSrchIpt"  style="float:left;margin-left:5px;"/>
                    <button class="btn btn-primary basicBtnSrch" @click="bizSearch">검색</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script type="text/javascript">
import Constant from '../../Constant';
//import { mapState } from 'vuex';

export default {
    name: 'biz-search',
    data() {
        return {
          
            searchType: '010',
            searchOptions: [
                {text: '사업이름', value:'010'},
                {text: '고객사', value:'020'}
            ],
            searchValue: ''
        }
    },
    methods : {
        bizSearch : function(){
            this.$store.dispatch(Constant.BIZ_SEARCH, { searchType: this.searchType, searchValue: this.searchValue});
        }
    }
}
</script>