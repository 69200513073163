<template>
    <div>
        <!--메인 이미지 -->
        <div style="">
            
            <intra-notice-list></intra-notice-list>
            
        </div>
        <!--contents -->
        
    </div>
</template>

<script>

import IntraNoticeList from './IntraNoticeList.vue';

export default {
    name: 'Notice',
    components : {IntraNoticeList}
}
</script>