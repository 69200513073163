<template>
    <div>
         <!--Top Menu -->
         <intra-top></intra-top>

        <form name="f" method="post" ref="f" @submit.prevent="boardSubmit" enctype="multipart/form-data">
        <input type="hidden" v-model="bulletin.board_id" />
        <div class="row justify-content-center" style="min-width:600px">
            <div class="row">
                <h2 class="card-title" style="font-weight:bold;margin:8px 0 -8px 0;"><i class="fas fa-square"></i> 게시글 수정하기</h2>
                <div class="card-body">
                    <div class="table-responsive table-bordered">
                        <table class="table">
                            <tbody>
                            <tr>
                                <th class="table-active">작성자</th>
                                <td class="txtL">{{uid}}</td>
                            </tr>
                            
                            <tr style="line-height:32px;" class="">
                                <th class="table-active w100">제목</th>
                                <td><input type="text"  v-model="bulletin.subject" style="width:100%" /></td>
                            </tr>
                            <tr style="line-height:32px;" class="">
                                <th class="table-active w100">내용</th>
                                <td><textarea v-model="bulletin.contents_plain" style="width:100%;height:400px;"></textarea></td>
                            </tr>
                            <tr style="line-height:32px;" class="">
                                <th class="table-active w100">첨부파일</th>
                                <td>
                                    <li class="txtL" scope="row" v-for="files in bulletin.files" :key="files.file_id" v-bind:id="'file_'+files.file_id">
                                        <a @click="fileDownload(files.file_id, files.org_file_name)" style="cursor:pointer;">{{files.org_file_name}}</a> 
                                        <a @click="fileDelete(files.file_id)" style="cursor:pointer;color:blue;font-weight:bold;margin-left:8px">[삭제]</a>
                                    </li>
                                    <input type="file" multiple name="file" id="file" style="width:100%" @change="onFileChange"/>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>        
            <div class="justify-content-between">
                <button type="button" class="btn btn-success" @click="goList">목록</button>
                <button type="button" class="btn btn-success" @click="goDelete" style="margin-left:8px">삭제</button>
                <button type="submit" class="btn btn-success" style="margin-left:8px">저장</button>
                <!--<button type="button" class="btn btn-success" @click="boardSubmit" style="margin-left:8px">저장</button>-->
                <button type="button" class="btn btn-info" style="display:none">다시쓰기</button>
            </div>

        </div>
        </form>   
            
    </div>
</template>

<!--<script setup>
    const submitBoard = e =>{
        const form = e.target;
        console.log(...new FormData(form).entries());
    }
</script>
-->

<script type="text/javascript">
import Constant from '../../Constant';
import axios from 'axios';
import { mapState } from 'vuex';
import IntraTop from './IntraTop';

export default {
    name : 'free-modify',
    component: {axios, IntraTop},
    beforeCreate: function(){
        console.log('FreeModify beforeCreate...');
         this.$store.dispatch(Constant.LOGIN_CHECK, this.$store.state.uid);
         
        /*
        if(this.$store.state.uid == "UID"){
            console.log("before:UID:" + this.$store.state.uid);
            this.$router.push('login');
        }
        else{
            console.log("before:UID:" + this.$store.state.uid);
        }
        */
    },
    data : function() {
        return {
            filesTempArr : []
        }
    },
    props : ['boardId', 'boardMode', 'nttId'],
    computed: {
        ...mapState([ 'noticeList', 'uid']),
        bulletin() {
          
            return this.$store.state.bulletin;
        }
        /*
        bulletin() {
            return{
                type: Object,
                mode: this.boardMode,
                board_id: Constant.BOARD_NOTICE,
                subject: '',
                contents: '',
                upt_id: this.uid,
                uid: this.uid

            }
        } 
        */           
    },
    methods: {
        boardSubmit: function(e){
            
            console.log("board mod ...");

            if(!confirm("등록 하시겠습니까?")){
                return false;
            }
            

            console.log(e.target);

            console.log(this.bulletin.ntt_id);
            console.log(this.bulletin.board_id);
            console.log(this.bulletin.subject);
            console.log(this.bulletin.upt_id);
            console.log(this.$store.state.uid);
            console.log("uid::" + this.$store.state.uid);

            /* 2022.07.24 신규코드 - 파일첨부 테스트 */
            
            const formdata = new FormData();    //formData 선언
            //const files = e.target.files;
            formdata.append("mode", "MOD");
            formdata.append("ntt_id", this.bulletin.ntt_id);
            formdata.append("boardId", this.bulletin.board_id);
            formdata.append("uid", this.$store.state.uid);
            formdata.append("subject", this.bulletin.subject);
            formdata.append("contents", this.bulletin.contents_plain);
            formdata.append('files', this.filesTempArr[0]);

            console.log(...formdata.entries());
            this.$store.dispatch(Constant.BOARD_ADD, formdata);

            /* 2022.07.24 정상코드 
            this.$store.dispatch(Constant.BOARD_ADD, {bulletin: this.bulletin, mode: this.boardMod, board_id: this.boardId, uid: this.uid});
            */

        },
        onFileChange: function(e){
            
            //const formData = new FormData();	// 파일을 전송할때는 FormData 형식으로 전송
            //this.uploadimageurl = [];		// uploadimageurl은 미리보기용으로 사용
            const files = e.target.files;
            console.log("file::" + files[0]);
            //formData.append('files', files[0])	// formData의 key: 'filelist', value: 이미지


           /* files.forEach((item) => {
                formData.append('files', item)	// formData의 key: 'filelist', value: 이미지
                const reader = new FileReader();
                //reader.onload = (e) => {
                    //this.uploadimageurl.push({url: e.target.result});
                    // e.target.result를 통해 이미지 url을 가져와서 uploadimageurl에 저장
                //};
                reader.readAsDataURL(item);
            });*/

            /*
            axios({
                url: "/backend/file/fileAdd",	// 이미지 저장을 위해 back서버와 통신
                method: "POST",
                headers: {'Content-Type': 'multipart/form-data'},	// 이걸 써줘야 formdata 형식 전송가능
                data: formData,
            }).then(res => {
                console.log(res);
               // this.imagecnt = file.length;	// 이미지 개수 저장
            }).catch(err => {
                alert(err);
            });
            */

           this.filesTempArr[0] = files[0];
           console.log("files:" + this.filesTempArr[0]);
        },
        goList: function(){
            this.$router.push("/intranet/free");
        },
        goDelete: function(){
            console.log(this.bulletin.ntt_id);
            if(!confirm("삭제 하시겠습니까?")){
                return false;
            }
            this.$store.dispatch(Constant.FREE_DELETE, { nttId: this.bulletin.ntt_id });
        },
        async fileDownload(file_id, file_name){
       
            const url = "/backend/file/fileDownload/"+file_id;
            const response = await axios.get(url, { responseType: "blob" });
            const blob = new Blob([response.data]);
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = file_name;
            link.click();
            URL.revokeObjectURL(link.href);
        
        },
        fileDelete: function(file_num){

            console.log ("type");
            console.log ( typeof file_num );

            if(confirm("삭제 하시겠습니까?")){
                console.log('file delete : ' + file_num);
                //this.$store.dispatch(Constant.FILE_DELETE, file_num);

                axios.post(Constant.BASE_URL + "/file/fileDelete", null, { params: {
                  
                        file_id : file_num,
                    
                }})
                .then((response) => {
                    console.log(response);
                    document.getElementById("file_"+file_num).remove();
                    //alert("정상 처리 되었습니다.");
                })
                .catch(function (error) { 
                    console.log(error);
                })

            } 
        }
    },
    mounted : function(){
        console.log("mounted::" + this.boardId);
        console.log("mounted::" + this.boardMode);
    }
    
}

</script>