<template>
    <div>
        <!--메인 이미지 -->
        <div style="">
            
            <intra-free-list></intra-free-list>
            
        </div>
        <!--contents -->
        
    </div>
</template>

<script>

import IntraFreeList from './IntraFreeList.vue';

export default {
    name: 'Free',
    components : {IntraFreeList}
}
</script>