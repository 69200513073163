<template>
    <div>
        <!--Top Menu -->
        <intra-top></intra-top>

        <!-- Contents -->
        <div class="row justify-content-between" style="margin:0; padding:0;">
            <div class="row" style="margin:0; padding:0;">
                    <div class="col" style="overflow:auto;max-width:1920px;margin:0; padding:0;">
                        <div class="row" style="margin:0; padding:0;">
                            <h2 style="margin:0; padding:0;">사원목록</h2>
                            <!--<div style="width:100%;border:1px solid #d4d4d4;color:black;">
                                <ul>
                                    <li v-for="notice in noticeList.noticeList" :key="notice.ntt_id">{{notice.subject}}</li>
                                </ul>
                            </div>-->
                            <div class="col">

                                <!--
                                <b-table hover show-empty="true"
                                    id="iwe-notice"
                                    :items="noticeLists"
                                    :per-page="5"
                                    :current-page="currentPage"
                                    :fields="fields"
                                empty-text="검색 결과가 없습니다."
                                >
                                <template #cell(boardDetail)="data">
                                {{data.subject}}
                                </template>

                                
                                    
                                </b-table>
                                -->
                                <table class="table table-striped table-hover" id="iwe-user">
                                    <thead class="thead-light">
                                        <tr>
                                            <th scope="col">ID</th>
                                            <th scope="col">이름</th>
                                            <th scope="col">직급</th>
                                            <th scope="col">수정일자</th>
                                            <!--<th scope="col">상세보기</th>-->
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr scope="row" v-for="user in userLists" :key="user.emp_no" @click="userDetail(user.user_id, $event)">
                                            <td class="txtL">{{user.user_id}}</td>
                                            <td>{{user.user_name}}</td>
                                            <td>{{user.posi_id}}</td>
                                            <td>{{user.upt_dt}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <b-pagination
                                    v-model="currentPage"
                                    :total-rows="rows"
                                    :per-page="perPage"
                                    aria-controls="iwe-user">
                                </b-pagination>
                            </div>
                            
                        </div><!--row-->
                    </div>
            </div>
            
            <!--검색-->
            <div class="row" style="padding:0; margin:0;">
                <userSearch></userSearch>
            </div>

            <div class="row justify-content-end" style="margin:5px 0; padding:0;">
                <div class="col-md-1" style="width:200px;margin-right:30px;margin:0; padding:0;height:30px;">
                    <button class="basicBtn btn btn-primary" @click="userForm">신규추가</button>
                </div>
            </div>
           
            <!-- <v-pagination v-model="page" :pages="10" :range-size="1" active-color="#DCEDFF" @update:modelValue="pageChanged"/> -->
        </div>
    </div>
</template>


<script type="text/javascript">
import Constant from '../../Constant';
import { mapState } from 'vuex';
import IntraTop from './IntraTop';
import UserSearch from './UserSearch';

export default { 
    name : 'user-list',
    components : { IntraTop, UserSearch },
    beforeCreate: function(){
        
        this.$store.dispatch(Constant.LOGIN_CHECK, this.$store.state.uid);
        if(this.$store.state.uid == "UID"){
            console.log("before:UID:" + this.$store.state.uid);
            this.$router.push('login');
        }
        else{
            console.log("before:UID:" + this.$store.state.uid);
            console.log('userList beforeCreate...');
            this.$store.dispatch(Constant.USER_LIST, 1);
        }
    },
     data(){
        return {
           
            currentPage: 1,
            perPage: 10,
        }
    },
    //props : ['boardId', 'nttId'],
    computed: {
       ...mapState([ 'userList' ]),
       rows(){
           console.log("rows:" + this.$store.state.userList.totalCount);
           return this.$store.state.userList.totalCount;
       },
       userLists(){
           console.log("noticeLists:" + this.$store.state.userList.userList.length);
           //return this.$store.state.userList.userList;
           return this.$store.state.userList.userList.slice((this.currentPage -1) * this.perPage, this.currentPage * this.perPage)
       }
    },
    
    methods: {
        
        userForm : function(){
            console.log('userForm()');
            this.$router.push({name: 'userForm', params: { userMode: 'ADD', userId:'' }});
        },
        userDetail : function(user_id, event){
            console.log(event);
            this.$store.dispatch(Constant.USER_DETAIL, { userId: user_id });
            //this.$router.push({name: 'boardForm', params: {boardMode: 'MOD', boardId: Constant.BOARD_NOTICE, nttId: ntt_id}})
        }
       
    },
    mounted : function(){
        var page = 1;
        console.log("mounted:" + this.$route.query);
        console.log("mounted:" + this.$route.query.page);

        if(this.$router.query && this.$route.query.page){
            page = parseInt(this.$route.query.page);
            this.currentPage = page;
        }
       
       this.$store.dispatch(Constant.USER_LIST, page);
        //this.$refs.pagebuttons.selected = page-1;

        console.log("mounted:" +  this.currentPage);
    }
    
}

</script>