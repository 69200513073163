<template>
    <div>
        <!--Top Menu -->
        <intra-top></intra-top>

        <!-- Contents -->
        <div class="row justify-content-between" style="margin:0; padding:0;min-width:600px;">
            <div class="row" style="margin:0; padding:0;">
                    <div class="col" style="overflow:auto;max-width:1920px;margin:0; padding:0;">
                        <div class="row" style="margin:0; padding:0;">
                            <h2 style="margin:0; padding:0;">사업실적</h2>
                            <!--<div style="width:100%;border:1px solid #d4d4d4;color:black;">
                                <ul>
                                    <li v-for="notice in noticeList.noticeList" :key="notice.ntt_id">{{notice.subject}}</li>
                                </ul>
                            </div>-->
                            <div class="col">
                                <table class="table table-striped table-hover" id="iwe-biz">
                                    <colgroup>
                                        <col style=""/>
                                        <col style="width:150px"/>
                                        <col style="width:110px"/>
                                    </colgroup>
                                    <thead class="thead-light">
                                        <tr>
                                            <th scope="col">사업이름</th>
                                            <th scope="col">고객사</th>
                                            <th scope="col">수정일자</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr scope="row" v-for="biz in performanceList" :key="biz.emp_no" @click="bizDetail(biz.biz_id, $event)">
                                            
                                            <td class="txtL">{{biz.biz_name}}</td>
                                            <td>{{biz.biz_customer}}</td>
                                            <td>{{biz.upt_dt}}</td>
                                        </tr>
                                        <tr scope="row" v-if="performanceList.length < 1">
                                            <td colspan="4" class="empty">조회 결과가 없습니다.</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <b-pagination
                                    v-model="currentPage"
                                    :total-rows="rows"
                                    :per-page="perPage"
                                    aria-controls="iwe-biz">
                                </b-pagination>
                            </div>
                        </div><!--row-->
                    </div>
            </div>
            
            <!--검색-->
            <div class="row" style="padding:0; margin:0;">
                <bizSearch></bizSearch>
            </div>
           
            <div class="row justify-content-end" style="margin:5px 0; padding:0;">
                    <button class="basicBtn btn btn-primary" @click="bizForm">신규추가</button>
            </div>
            <!-- <v-pagination v-model="page" :pages="10" :range-size="1" active-color="#DCEDFF" @update:modelValue="pageChanged"/> -->
        </div>
    </div>
</template>


<script type="text/javascript">
import Constant from '../../Constant';
//import { mapState } from 'vuex';
import IntraTop from './IntraTop';
import BizSearch from './BizSearch';

export default { 
    name : 'biz-list',
    components : { IntraTop, BizSearch },
    beforeCreate: function(){
        console.log('bizList beforeCreate...');
        this.$store.dispatch(Constant.LOGIN_CHECK, this.$store.state.uid);
        //this.$store.dispatch(Constant.BIZ_LIST, 1);
        /*
        if(this.$store.state.uid == "UID"){
            console.log("before:UID:" + this.$store.state.uid);
            this.$router.push('login');
        }
        else{
            console.log("before:UID:" + this.$store.state.uid);
        }
        */
    },
     data(){
        return {
           
            currentPage: 1,
            perPage: 10,
        }
    },
    //props : ['boardId', 'nttId'],
    computed: {
       
       rows(){
           console.log("rows:" + this.$store.state.performanceList.totalCount);
           return this.$store.state.performanceList.totalCount;
       },
       performanceList(){
           console.log("performanceList:" + this.$store.state.performanceList.performanceList.length); 
           return this.$store.state.performanceList.performanceList.slice((this.currentPage -1) * this.perPage, this.currentPage * this.perPage)
       }
    },
    
    methods: {
        
        bizForm : function(){
            console.log('bizForm()');
            this.$store.dispatch(Constant.BIZ_FORM, { bizMode: 'ADD', bizId:'' });
            //this.$router.push({name: 'bizForm', params: { bizMode: 'ADD', bizId:'' }});
        },
        bizDetail : function(biz_id, event){
            console.log(event);
            this.$store.dispatch(Constant.BIZ_DETAIL, { bizId: biz_id });
            //this.$router.push({name: 'boardForm', params: {boardMode: 'MOD', boardId: Constant.BOARD_NOTICE, nttId: ntt_id}})
        }
       
    },
    mounted : function(){
        var page = 1;
        console.log("mounted:" + this.$route.query);
        console.log("mounted:" + this.$route.query.page);

        if(this.$router.query && this.$route.query.page){
            page = parseInt(this.$route.query.page);
            this.currentPage = page;
        }
       
       this.$store.dispatch(Constant.BIZ_LIST, page);
        //this.$refs.pagebuttons.selected = page-1;

        console.log("mounted:" +  this.currentPage);
    }
    
}

</script>