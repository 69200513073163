<template>
    <div>
        
         <!--Top Menu -->
         <intra-top></intra-top>

        <form name="f" method="post" ref="f" @submit.prevent="userSubmit" enctype="multipart/form-data">
        <div class="row justify-content-center" style="min-width:600px;">
            <div class="row">   
                <h2 class="card-title" style="font-weight:bold;margin:8px 0 -8px 0;"><i class="fas fa-square"></i> 사원 수정</h2>
                <div class="card-body">
                    <div class="table-responsive table-bordered">
                        <table class="table">
                            <colgroup>
                                <col style="width:110px" />
                                <col style="" />
                                <col style="width:120px" />
                                <col style="" />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th class="table-active w110">ID</th>
                                    <td class="txtL">{{employee.user_id}}</td>
                                    <td rowspan="7" colspan="2">
                                        <img :src="'/img/emp_pic/'+employee.user_pic" style="max-width:250px;"/>
                                    </td>
                                </tr>
                                <tr>
                                    <th class="table-active w110">사원번호</th>
                                    <td class="txtL">{{employee.emp_no}}</td>
                                </tr>
                                
                                <tr>
                                    <th class="table-active w110">이름</th>        
                                    <td class="txtL"><input type="text" v-model="employee.user_name" style="width:100%" /></td>
                                </tr>
                                <tr>
                                    <th class="table-active w110">부서</th>
                                    <td class="txtL">
                                        <select v-model="employee.dept_id" class="bdSrchOp">
                                            <option v-for="option in deptOptions" :key="option.value" :value="option.value">
                                                {{option.text}}
                                            </option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <th class="table-active w110">직급</th>
                                    <td class="txtL">
                                        <select v-model="employee.posi_id" class="bdSrchOp">
                                            <option v-for="option in posiOptions" :key="option.value" :value="option.value">
                                                {{option.text}}
                                            </option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <th class="table-active w110">사용자 권한</th>
                                    <td class="txtL">
                                        <select v-model="employee.user_grant" class="bdSrchOp">
                                            <option v-for="option in grantOptions" :key="option.value" :value="option.value">
                                                {{option.text}}
                                            </option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <th class="table-active w110">근무여부</th>
                                    <td class="txtL" colspan="4">
                                        <select v-model="employee.use_yn" class="bdSrchOp2">
                                            <option v-for="option in ynOptions" :key="option.value" :value="option.value">
                                                {{option.text}}
                                            </option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <th class="table-active w110">우편번호</th>
                                    <td class="txtL"><input type="text" v-model="employee.zip_code" style="width:30%;min-width:150px;float:left;" /></td>
                                    <td colspan="2"><input type="file" multiple name="file" id="file" style="width:100%" @change="onFileChange"/></td>
                                </tr>
                                <tr>
                                    <th class="table-active w110">주소</th>
                                    <td colspan="3">
                                        <input type="text" v-model="employee.addr1" style="width:95%;min-width:150px;float:left;" />
                                    </td>
                                </tr>
                                <tr>
                                    <th class="table-active w110">이메일</th>    
                                    <td class="txtL" colspan="3"><input type="text" v-model="employee.e_mail" style="width:100%" /></td>
                                </tr>
                                <tr>
                                    <th class="table-active w110">전화번호</th>
                                    <td class="txtL"><input type="text" v-model="employee.tel_no" style="width:100%" /></td>
                                    <th class="table-active w110">모바일</th>
                                    <td class="txtL"><input type="text" v-model="employee.mobile_tel_no" style="width:100%" /></td>
                                </tr>
                                <tr>
                                    <th class="table-active w110">입사일자</th>
                                    <td class="txtL"><input type="text" v-model="employee.enter_dt" style="width:30%;min-width:150px;float:left;" /></td>
                                    <th class="table-active w110">퇴사일자</th>
                                    <td class="txtL"><input type="text" v-model="employee.resign_dt" style="width:30%;min-width:150px;float:left;" /></td>
                                    
                                </tr>
                                
                                <tr>
                                    <th class="table-active w110">비밀번호</th>        
                                    <td class="txtL"><input type="password" v-model="employee.user_pw" style="width:100%" /></td>
                                    <th class="table-active w110">비밀번호 확인</th>    
                                    <td class="txtL"><input type="password" v-model="employee.user_pw_chk" style="width:100%" /></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>     
                        
                
                               
            <div class="justify-content-between">
                <button type="button" class="btn btn-success" @click="goList">목록</button>
                <button type="button" class="btn btn-success" @click="goDelete" style="margin-left:8px">삭제</button>
                <button type="submit" class="btn btn-success" style="margin-left:8px">저장</button>
                <!--<button type="button" class="btn btn-success" @click="boardSubmit" style="margin-left:8px">저장</button>-->
                <button type="button" class="btn btn-info" style="display:none">다시쓰기</button>
            </div>

        </div>
        </form>   
            
    </div>
</template>

<!--<script setup>
    const submitBoard = e =>{
        const form = e.target;
        console.log(...new FormData(form).entries());
    }
</script>
-->

<script type="text/javascript">
import Constant from '../../Constant';
import axios from 'axios';
import { mapState } from 'vuex';
import IntraTop from './IntraTop';

export default {
    name : 'user-modify',
    component: {axios, IntraTop},
    beforeCreate: function(){
        console.log('UserModify beforeCreate...');
         this.$store.dispatch(Constant.LOGIN_CHECK, this.$store.state.uid);
         
        /*
        if(this.$store.state.uid == "UID"){
            console.log("before:UID:" + this.$store.state.uid);
            this.$router.push('login');
        }
        else{
            console.log("before:UID:" + this.$store.state.uid);
        }
        */
    },
    data : function() {
        return {
            filesTempArr : [],
            posiOptions: [
                {text: '사원', value:'100'},
                {text: '주임', value:'200'},
                {text: '대리', value:'300'},
                {text: '과장', value:'400'},
                {text: '차장', value:'500'},
                {text: '부장', value:'600'},
                {text: '임원', value:'700'},
                {text: 'CEO', value:'900'},
                {text: '기타', value:'010'},
            ],
            deptOptions: [
                {text: '개발1팀', value:'100'},
                {text: '개발2팀', value:'200'},
                {text: '지원', value:'300'},
                {text: '총무', value:'400'},
                {text: 'CEO', value:'900'},
            ],
            grantOptions: [
                {text: '일반', value:'100'},
                {text: '관리자', value:'900'},
            ],
            ynOptions: [
                {text: '근무', value:'t'},
                {text: '퇴사', value:'f'},
            ]
        }
    },
    props : [ 'userMode', 'userId'],
    computed: {
        ...mapState([ 'userList', 'uid']),
        employee() {
          
            return this.$store.state.employee;
        }
        /*
        bulletin() {
            return{
                type: Object,
                mode: this.boardMode,
                board_id: Constant.BOARD_NOTICE,
                subject: '',
                contents: '',
                upt_id: this.uid,
                uid: this.uid

            }
        } 
        */           
    },
    methods: {
        userSubmit: function(e){
            
            if( !confirm("등록하시겠습니까?")){
                return false;
            }
            console.log(e.target);

            console.log(this.employee.user_id);
            console.log(this.employee.user_name);
            console.log(this.employee.dept_id);
            console.log(this.employee.posi_id);
            
            if(this.employee.user_pw != this.employee.user_pw_chk){
                alert("비밀번호가 일치하지 않습니다.");
                return;
            }


            /* 2022.07.24 신규코드 - 파일첨부 테스트 */
            
            const formdata = new FormData();    //formData 선언
            //const files = e.target.files;
            formdata.append("mode", "MOD");
            formdata.append("user_id", this.employee.user_id);
            formdata.append("uid", this.$store.state.uid);
            formdata.append("user_pw", this.employee.user_pw);
            formdata.append("user_pw_chk", this.employee.user_pw_chk);
            formdata.append("user_name", this.employee.user_name);
            formdata.append("e_mail", this.employee.e_mail);
            formdata.append("tel_no", this.employee.tel_no);
            formdata.append("mobile_tel_no", this.employee.mobile_tel_no);
            formdata.append("dept_id", this.employee.dept_id);
            formdata.append("posi_id", this.employee.posi_id);
            formdata.append("zip_code", this.employee.zip_code);
            formdata.append("addr1", this.employee.addr1);
            formdata.append("user_grant", this.employee.user_grant);
            formdata.append("day_off", this.employee.day_off);
            formdata.append("enter_dt", this.employee.enter_dt);
            formdata.append("resign_dt", this.employee.resign_dt);
            formdata.append("use_yn", this.employee.use_yn);
            formdata.append('files', this.filesTempArr[0]);

            console.log(...formdata.entries());
            this.$store.dispatch(Constant.USER_ADD, formdata);

            /* 2022.07.24 정상코드 
            this.$store.dispatch(Constant.BOARD_ADD, {bulletin: this.bulletin, mode: this.boardMod, board_id: this.boardId, uid: this.uid});
            */

        },
        onFileChange: function(e){
            
            //const formData = new FormData();	// 파일을 전송할때는 FormData 형식으로 전송
            //this.uploadimageurl = [];		// uploadimageurl은 미리보기용으로 사용
            const files = e.target.files;
            console.log("file::" + files[0]);
            //formData.append('files', files[0])	// formData의 key: 'filelist', value: 이미지


           /* files.forEach((item) => {
                formData.append('files', item)	// formData의 key: 'filelist', value: 이미지
                const reader = new FileReader();
                //reader.onload = (e) => {
                    //this.uploadimageurl.push({url: e.target.result});
                    // e.target.result를 통해 이미지 url을 가져와서 uploadimageurl에 저장
                //};
                reader.readAsDataURL(item);
            });*/

            /*
            axios({
                url: "/backend/file/fileAdd",	// 이미지 저장을 위해 back서버와 통신
                method: "POST",
                headers: {'Content-Type': 'multipart/form-data'},	// 이걸 써줘야 formdata 형식 전송가능
                data: formData,
            }).then(res => {
                console.log(res);
               // this.imagecnt = file.length;	// 이미지 개수 저장
            }).catch(err => {
                alert(err);
            });
            */

           this.filesTempArr[0] = files[0];
           console.log("files:" + this.filesTempArr[0]);
        },
        goList: function(){
            this.$router.push("/intranet/userList");
        },
        goDelete: function(){
            console.log(this.employee.user_id);
            if(!confirm("삭제 하시겠습니까?")){
                return false;
            }
            this.$store.dispatch(Constant.USER_DELETE, { userId: this.employee.user_id });
        },
        fileDelete: function(file_num){

            console.log ("type");
            console.log ( typeof file_num );

            if(confirm("삭제 하시겠습니까?")){
                console.log('file delete : ' + file_num);
                //this.$store.dispatch(Constant.FILE_DELETE, file_num);

                axios.post(Constant.BASE_URL + "/file/fileDelete", null, { params: {
                  
                        file_id : file_num,
                    
                }})
                .then((response) => {
                    console.log(response);
                    document.getElementById("file_"+file_num).remove();
                    //alert("정상 처리 되었습니다.");
                })
                .catch(function (error) { 
                    console.log(error);
                })

            } 
        }
    },
    mounted : function(){
        console.log("mounted::" + this.userId);
        console.log("mounted::" + this.userMode);
    }
    
}

</script>