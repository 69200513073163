<template>
    <div>
        <!--Top Menu -->
        <intra-top></intra-top>

        <!-- Contents -->
        <form name="f" method="post" ref="f" @submit.prevent="bizSubmit" enctype="multipart/form-data">

        <div class="row justify-content-center" style="min-width:600px;">
            <div class="row">
                <h4 class="card-title" style="font-weight:bold;margin:8px 0 -8px 0;"><i class="fas fa-square"></i> 사업실적 수정</h4>
                <div class="card-body">
                    <div class="table-responsive table-bordered">
                        <table class="table">
                            <colgroup>
                                <col style="width:120px" />
                                <col style="" />
                                <col style="width:110px" />
                                <col style="" />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th class="table-active w120">사업 번호</th>
                                    <td class="txtL">{{performance.biz_id}}</td>
                                    <td rowspan="4" colspan="2">
                                        <img :src="'/img/biz/'+performance.img" style="width:100%;max-width:150px;"/>
                                    </td>
                                </tr>
                                <tr>
                                    <th class="table-active w120">사업 코드</th>
                                    <td class="txtL">{{performance.biz_code}}</td>
                                </tr> 
                                <tr>
                                    <th class="table-active w120">발주처</th>        
                                    <td class="txtL"><input type="text" v-model="performance.biz_order" style="width:100%" /></td>
                                </tr>
                                <tr>
                                    <th class="table-active w120">고객사</th>    
                                    <td class="txtL"><input type="text" v-model="performance.biz_customer" style="width:100%" /></td>
                                </tr>
                                <tr>
                                    <th class="table-active w120">사업 이름</th>
                                    <td class="txtL" colspan="2"><input type="text" v-model="performance.biz_name" style="width:100%" /></td>
                                    <td class="txtL" colspan="3"><input type="file" multiple name="file" id="file" style="width:100%" @change="onFileChange"/></td>
                                </tr>
                                <tr>
                                    <th class="table-active w120">사업 시작</th>
                                    <td class="txtL"><input type="text" v-model="performance.from_dt" style="width:100%" /></td>
                                    <th class="table-active w120">사업 종료</th>
                                    <td class="txtL"><input type="text" v-model="performance.to_dt" style="width:100%" /></td>
                                </tr>
                                <tr>
                                    <th class="table-active w120">사업 기반기술</th>
                                    <td class="txtL" colspan="3"><input type="text" v-model="performance.biz_skill" style="width:100%" /></td>
                                </tr>
                                <tr>
                                    <th class="table-active w120">공개여부</th>
                                    <td class="txtL">
                                        <select v-model="performance.open_yn" class="bdSrchOp2">
                                            <option v-for="option in openOptions" :key="option.value" :value="option.value">
                                                {{option.text}}
                                            </option>
                                        </select>
                                    </td>
                                    <th class="table-active w120">사용여부</th>
                                    <td class="txtL" colspan="2">
                                        <select v-model="performance.use_yn" class="bdSrchOp2">
                                            <option v-for="option in ynOptions" :key="option.value" :value="option.value">
                                                {{option.text}}
                                            </option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <th class="table-active w120">메모</th>
                                    <td class="txtL" colspan="3"><input type="text" v-model="performance.memo" style="width:100%" /></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                    
            </div>

            <div class="justify-content-between">
                <button type="button" class="btn btn-success" @click="goList">목록</button>
                <button type="button" class="btn btn-success" @click="goDelete" style="margin-left:8px">삭제</button>
                <button type="submit" class="btn btn-success" style="margin-left:8px">저장</button>
                
            </div>
        </div>
        </form>
    </div>
</template>



<script type="text/javascript">
import Constant from '../../Constant';
import { mapState } from 'vuex';
import IntraTop from './IntraTop';
//import axios from 'axios';

export default { 
    name : 'biz-modify',
    components : { IntraTop},
    beforeCreate: function(){
        console.log('BizModify beforeCreate...');
        this.$store.dispatch(Constant.LOGIN_CHECK, this.$store.state.uid);
        //this.$store.dispatch(Constant.BOARD_DETAIL, this.nttId);
        /*
        if(this.$store.state.uid == "UID"){
            console.log("before:UID:" + this.$store.state.uid);
            this.$router.push('login');
        }
        else{
            console.log("before:UID:" + this.$store.state.uid);
        }
        */
    },
    
    props : [ 'bizMode', 'bizId'],
    computed: {
       ...mapState([ 'performance' ]),
       performance() {
          
          return this.$store.state.performance;
      }
    },
    data : function() {
        return {
            filesTempArr : [],
            openOptions: [
                {text: '공개', value:'t'},
                {text: '비공개', value:'f'},
            ],
            ynOptions: [
                {text: '예', value:'t'},
                {text: '아니요', value:'f'},
            ]
        }
    },
    methods: {
        bizSubmit: function(e){
            
            if( !confirm("등록하시겠습니까?")){
                return false;
            }
            console.log(e.target);

            console.log(this.performance.biz_id);
            console.log(this.performance.biz_name);
            console.log(this.performance.biz_code);
                        
            
            /* 2022.07.24 신규코드 - 파일첨부 테스트 */
            
            const formdata = new FormData();    //formData 선언
            //const files = e.target.files;
            formdata.append("mode", 'MOD');
            formdata.append("uid", this.$store.state.uid);
            formdata.append("biz_id", this.performance.biz_id);
            formdata.append("biz_name", this.performance.biz_name);
            formdata.append("biz_code", this.performance.biz_code);
            formdata.append("biz_order", this.performance.biz_order);
            formdata.append("biz_customer", this.performance.biz_customer);
            formdata.append("from_dt", this.performance.from_dt);
            formdata.append("to_dt", this.performance.to_dt);
            formdata.append("biz_skill", this.performance.biz_skill);
            formdata.append("open_yn", this.performance.open_yn);
            formdata.append("use_yn", this.performance.use_yn);
            formdata.append("memo", this.performance.memo);
            formdata.append('files', this.filesTempArr[0]);

            console.log(...formdata.entries());
            this.$store.dispatch(Constant.BIZ_ADD, formdata);

            /* 2022.07.24 정상코드 
            this.$store.dispatch(Constant.BOARD_ADD, {bulletin: this.bulletin, mode: this.boardMod, board_id: this.boardId, uid: this.uid});
            */

        },
        onFileChange: function(e){
            
           const files = e.target.files;
           console.log("file::" + files[0]);
           
           this.filesTempArr[0] = files[0];
           console.log("files:" + this.filesTempArr[0]);
        },
        goList: function(){
            this.$router.push("/intranet/bizList");
        },
        goDelete: function(){
            console.log(this.performance.biz_id);
            if(!confirm("삭제 하시겠습니까?")){
                return false;
            }
            this.$store.dispatch(Constant.BIZ_DELETE, { bizId: this.performance.biz_id });
        },
        
    },
    mounted : function(){
        //console.log("mounted::" + this.boardId);
        //console.log("mounted::" + this.boardMode);
    }
    
}

</script>